<template>
  <b-card>
    <b-overlay
      :show="!closedTickets"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">
        <div class="float-left ir-btn-wrapper">
          <b-form-select
            id="referral-type"
            v-model="category"
            :options="ticketCategory"
            class="closed-box1"
            @change="searchTableCategory"
          />
        </div>
        <!-- search input -->
        <div
          class="custom-search d-flex justify-content-end"
        >
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block closed-box2"
                @keydown="searchTable"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="closedTickets"
          :columns="columns"
          :rows="closedTickets"
          :sort-options="{
            enabled: true,
          }"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <router-link :to="`/tickets/${props.row.id}`">{{ props.row.id }}</router-link>
            </span>

            <!-- Column: Email -->
            <span v-else-if="props.column.field === 'user_email'">
              <router-link :to="`/user/details/${props.row.uid}`">{{ props.row.user_email }}</router-link>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'ticket_status'">
              <b-badge :variant="statusVariant(props.row.ticket_status)">
                {{ ticketStatus(props.row) }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'created_at'">
              {{ getElapseDate(props.row.created_at) }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>

  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BBadge, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BBadge,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormInput,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      category: 'All',
      ticketCategory: [
        'All',
      ],
      item: [],
      rows: [],
      filter: {
        issueStart: null,
        issueEnd: null,
        dueStart: null,
        dueEnd: null,
        paidOn: null,
        status: null,
        clients: null,
        invoiceType: null,
      },
      searchTerm: '',
      closedTickets: null,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    columns() {
      const columns = [
        {
          label: '#',
          field: 'id',
          sortable: true,
          sortFn: this.sortFnID,
          tdClass: 'w-10',
        },
        {
          label: 'Date',
          field: 'created_at',
          sortable: true,
          sortFn: this.sortFnDate,
        },
        {
          label: 'Subject',
          field: 'ticket_subject',
        },
        {
          label: 'Subject',
          field: 'ticket_type',
          hidden: false,
        },
      ]
      if (this.user.userRole === 'Admin') {
        columns.push(
          {
            label: 'Domain',
            field: 'user_domain',
          },
          {
            label: 'Email',
            field: 'user_email',
          },
        )
      }
      columns.push({
        label: 'Status',
        field: 'ticket_status',
        sortable: false,
      })
      return columns
    },
    statusVariant() {
      const statusColor = {
        waiting: 'info',
        onhold: 'secondary',
        in_progress: 'warning',
        open: 'primary',
        closed: 'secondary text-white',
      }

      return status => statusColor[status]
    },
    users() {
      return this.$store.state.users.expiredUsers
    },
    invoiceData() {
      return { merged: [] }
    },
  },
  created() {
    const { uid } = this.$route.query
    let uri = 'tickets/fetchClosedTickets'
    if (uid) uri = 'tickets/fetchClosedTicketsFresh'
    this.$store.dispatch(uri, uid).then(() => {
      this.closedTickets = this.$store.state.tickets.closedTickets.data
      this.closedTickets.forEach(item => {
        if (item.ticket_type !== 'all') { this.ticketCategory.push(item.ticket_type) }
      })
      this.ticketCategory.sort()
      this.ticketCategory = this.sortRemoveDuplicate(this.ticketCategory)
    })
  },
  methods: {
    ticketStatus(ticket) {
      let status = ''
      if (ticket.ticket_status === 'closed') status = 'Closed'
      else if (ticket.ticket_status === 'onhold') status = 'On Hold'
      else if (ticket.ticket_status === 'waiting') status = 'Waiting for user reply'
      else if (ticket.ticket_status === 'in_progress') status = 'In progress'
      return status
    },
    getElapseDate(Date) {
      return moment(Date).fromNow()
    },
    sortFnDate(x, y, col, rowX, rowY) {
      /* eslint-disable */
      const value1 = Date.parse(this.filterFormatDate(rowX.created_at));
      const value2 = Date.parse(this.filterFormatDate(rowY.created_at));

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0));
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
    cFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
     sortFnID(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = Number(rowX.id)
      const value2 = Number(rowY.id)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    searchTable() {
      this.category = 'All'
      this.searchTerm = this.searchTermTxt.trim()
    },
    searchTableCategory() {
      let category = this.category.toLowerCase()
      category = category.trim()
      if (category === 'all') this.searchTerm = ''
      else this.searchTerm = category
    },

    sortRemoveDuplicate(a) {
      const result = []
      a.forEach(item => {
        if (result.indexOf(item) < 0) {
          result.push(item)
        }
      })
      return result
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}

.closed-box1{
  width: 16rem
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mr-1{
    display: none;
  }
  .closed-box1 {
    width: 84% !important
  }
  .closed-box2{
    float: left !important;
    margin-top: 5px;
    margin-bottom: -15px;
    width: 428px;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .closed-box1 {
    width: 80% !important
  }
  .closed-box2{
    width: 428px;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

  .closed-box1 {
    width: 74% !important
  }
  .closed-box2{
    width: 428px;
  }

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {
  .closed-box1 {
    width: 71% !important
  }
  .closed-box2{
    width: 428px;
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .closed-box1 {
    width: 68% !important
  }
  .closed-box2{
    width: 428px;
  }
}

.dark-layout a {
  color: #fb50a1 !important;
}
</style>
